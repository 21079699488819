.counter-bg {
    background-image: url('../../../assets/Images/banner2.jpg');
    background-size: cover;
    background-repeat: no-repeat, no-repeat, no-repeat;
    padding: 16px;
    height: 70vh;
    background-position: center;
    position: relative;
    font-family: 'Nunito', sans-serif;

    h1 {
        text-align: center;
        color: #fff;
        position: relative;
        top: 20%;
    }

    h4 {
        text-align: center;
        color: #fff;
        position: relative;
        top: 25%;
    }

    .wave-buttons {
        position: relative;
        display: flex;
        gap: 12px;
        justify-content: center;
        top: 30%;

        .wave-button {
            padding: 0 18px;
            border: 2px solid;
        }
    }
}

.counter-bg:before {
    content: "";
    background: rgb(16 15 19 / 66%);
    position: absolute;
    top: 0;
    min-height: 100%;
    left: 0;
    right: 0;
}

.counter-container {
    position: relative;
    z-index: 1;
    margin-top: -25vh;

    .row {
        .col {
            padding: 8px;
        }
    }

    .counter {
        width: 100%;
        height: 100%;
        display: flex;
        background: #fff;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        // border: 2px solid #000;
        box-shadow: 4px 4px 4px 4px rgba(66, 76, 125, 0.2);
        box-sizing: border-box;
        border-radius: 16px;
        text-align: center;
        padding: 20px;
        gap: 8px;

        img {
            width: 60px;
            height: 60px;
            border-radius: 50%;
        }

        .count-text {
            font-size: 18px;
            font-weight: bold;
            font-family: 'Nunito', sans-serif;
            text-align: center;
        }

        .timer {
            p {
                font-size: 2.6em;
                font-weight: 600;
                text-align: center;
            }
        }
    }

    .counter:hover {
        background: #0d6efd;
        color: #fff;

        img {
            background-color: #fff;
        }
    }
}