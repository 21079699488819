.footer {
    background: linear-gradient(105deg, #6e99e6, #093c94);
    padding-top: 40px;
    margin-top: 30px;
    // padding-bottom: 40px;

    a,
    a:hover,
    a:focus,
    a:active {
        text-decoration: none;
        outline: none;
    }

    a,
    a:active,
    a:focus {
        color: #333;
        text-decoration: none;
        transition-timing-function: ease-in-out;
        -ms-transition-timing-function: ease-in-out;
        -moz-transition-timing-function: ease-in-out;
        -webkit-transition-timing-function: ease-in-out;
        -o-transition-timing-function: ease-in-out;
        transition-duration: .2s;
        -ms-transition-duration: .2s;
        -moz-transition-duration: .2s;
        -webkit-transition-duration: .2s;
        -o-transition-duration: .2s;
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    img {
        max-width: 100%;
        height: auto;
    }

    section {
        padding: 60px 0;
        /* min-height: 100vh;*/
    }


}

/*END FOOTER SOCIAL DESIGN*/

@media only screen and (max-width:768px) {
    .single_footer {
        margin-bottom: 30px;
    }
}

.single_footer h4 {
    color: #fff;
    margin-top: 0;
    margin-bottom: 25px;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
}

.single_footer h4::after {
    content: "";
    display: block;
    height: 2px;
    width: 40px;
    background: #fff;
    margin-top: 20px;
}

.single_footer p {
    color: #fff;
}

.single_footer ul {
    margin: 0;
    padding: 0;
    list-style: none;
}


.single_footer ul li a {
    color: #fff;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    line-height: 36px;
    font-size: 15px;
    text-transform: capitalize;
}

.single_footer ul li a:hover {
    color: #ff3666;
}


.single_footer_address ul li {
    color: #fff;
}

.single_footer_address ul li span {
    font-weight: 400;
    color: #fff;
    line-height: 28px;
}

.contact_social ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

/*START SOCIAL PROFILE CSS*/
.social_profile {
    margin-top: 40px;
}

.social_profile ul {
    list-style: outside none none;
    margin: 0;
    padding: 0;
}

.social_profile ul li {
    float: left;
}

.social_profile ul li a {
    text-align: center;
    border: 0px;
    text-transform: uppercase;
    transition: all 0.3s ease 0s;
    margin: 0px 5px;
    font-size: 18px;
    color: #fff;
    border-radius: 30px;
    width: 50px;
    height: 50px;
    line-height: 50px;
    display: block;
    border: 1px solid rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width:768px) {
    .social_profile ul li a {
        margin-right: 10px;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width:480px) {
    .social_profile ul li a {
        width: 40px;
        height: 40px;
        line-height: 40px;
    }
}

.social_profile ul li a:hover {
    background: #1abc9c;
    border: 1px solid #ff3666;
    color: #fff;
    border: 0px;
}

/*END SOCIAL PROFILE CSS*/
.copyright {
    padding-top: 20px;
    color: #fff;
    font-size: 15px;
    border-top: 1px solid rgba(255, 255, 255, 0.4);
    text-align: center;
}

.copyright a {
    color: #000;
    transition: all 0.2s ease 0s;
}

.copyright a:hover {
    color: #1abc9c;
}