.service-cards {
    margin-top: 32px;

    .card-wrapper-col {
        margin: 4px 0;
    }

    .card {
        background-color: #fff;
        opacity: 0.6;
        border: 2px solid #fff;
        margin: 4px;
        border-radius: 20px;
        padding: 12px;
        height: 100%;

        .whatsapp-icon {
            display: none;
        }

        // align-items: center;
        .card-image {
            width: 50px;
            height: 50px;
            border-radius: 50%;
        }

        .card-title {
            font-size: 22px;
            color: #000;
        }

        .card-subtitle {
            color: #000;
        }

        .card-text {
            font-size: 12px;
            text-align: justify;
            color: #000;
            // line-break: anywhere;
        }

        .card-link {
            text-decoration: none;
            padding: 4px 12px;
            border-radius: 8px;
            color: #000;
            font-size: 14px;
        }
    }
}