.header-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}


@media only screen and (max-width: 551px) {
    .testimonial {
        height: 285px !important;
    }

    .testimonial-slider-wrapper {
        flex-direction: column !important;
    }

    .testi-des {
        padding: 0 40px;
    }
}