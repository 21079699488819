.card {
    background-color: transparent;
    display: flex;
    align-items: flex-start;
    border: none;
    text-align: left;

    .card-subheading {
        padding: 8px 0px;
    }

    .card-link:hover {
        background-color: #0d6efd;
        color: #fff;
    }
    .whatsapp-icon{
        padding: 0 0 0 8px;
    }
}

@media only screen and (max-width: 767px) {

    .card-body {
        padding: 4px;

        .card-title {
            font-size: 16px;
        }

        .card-text {
            font-size: 12px;
        }
    }
}