.hero-bg{
    width: 100%;
    height: 100vh;
    object-fit: cover;
}
.hero-overlay {
    width: 100%;
    height: 100vh;
    position: absolute;
    top: 9vh;
    left: 0;
    background: #001;
    opacity: 0.5;
}

.hero-section {
    width: 100%;
    background-color: azure;
    background-size: cover;
    background-position: center;

    .hero-section-content {
        position: absolute;
        top: 40%;
        left: 10%;
        width: auto;
        color: #fff;

        h1,
        h2 {
            font-weight: 700;
            font-family: 'Poppins', sans-serif;
        }
    }
}
