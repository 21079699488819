.gallery {
    column-count: 4;
    column-width: 20%;
    column-gap: 10px;
    padding: 0 12px;

    .gallery-images {
        width: 100%;
        transition: all 350ms ease;
        cursor: pointer;
        margin-bottom: 12px;
    }

    .gallery-images:hover {
        filter: opacity(0.8);
    }

}

.fullscreen-image-container {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #000;
    transition: opacity .4s ease, visibility .4s ease, transform .5s ease-in-out;

    img {
        position: absolute;
        top: 10%;
        width: auto;
        max-width: 100%;
        height: auto;
        max-height: 90vh;
        display: block;
        line-height: 0;
        box-sizing: border-box;
        padding: 20px 0 20px;
        margin: 0 auto;
    }

    .close-fullscreen-button {
        position: absolute;
        top: 10%;
        right: 5%;
        background: #fff;
        border-radius: 10px;
        width: 40px;
        height: 40px;
        text-align: center;
    }
}

@media (max-width:1280px) {
    .gallery {
        column-count: 3;
        // column-width: 33%;
    }
}

@media (max-width:991px) {  
    .gallery {
        column-count: 2;  
    }
}

@media (max-width:414px) {
    .gallery {
        column-count: 1;
        column-width: 100%;
    }

    // TODO
    .fullscreen-image-container {
        width: 100vw;
        img {
            width: 100vw;
            height: auto;
        }
    }
}