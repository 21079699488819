.slider-image-wrapper {
    width: 100vw;
    height: 91vh;
    overflow: hidden;

    .slider-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.carousel-caption {
    background: rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    left: 0;

    h1{
        text-shadow: 2px 2px #000000;
    }
  }

@media only screen and (max-width: 767px) {
    .slider-image-wrapper {
        width: 100%;
        height: 30vh;
    }
    .carousel-caption {
        padding: 4px 0;
        h1{
            font-size: 18px;
        }
        p{
            font-size: 12px;
        }
    }
}