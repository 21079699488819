.navbar {
    .brand-logo {
        height: 8vh;
        width: auto;
    }
}

.navbar-glassy {
    background: rgba(255, 255, 255, 0.4) !important;
    backdrop-filter: blur(10px);
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
    height: 9vh;
}

.card-link {
    border: 2px solid #0d6efd;
    border-style: solid;
    border-radius: 025px 025px 0px 025px !important;
    padding: 12px 48px 12px 48px;
    text-decoration: none;
    margin-bottom: 12px;
}

@media only screen and (max-width: 767px) {
    .offcanvas {
        width: 60% !important;

        .brand-logo {
            display: none;
        }
    }

    .navbar {
        .brand-logo {
            height: 5vh;
        }
    }

    .offcanvas-header {
        padding: 13px;

        .brand-logo {
            height: 5vh;
        }
    }

    .card-link {
        padding: 8px 24px 8px 24px !important;
    }
}