// Homepage About Section 
.about-desc {
    text-align: justify;
    padding: 0 1rem 0 0;
}

.content-1-grids {
    .content-1-right {
        p {
            text-indent: 4em;
        }

        span {
            font-weight: 600;
        }
    }

    .about-us-image-wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        .about-us-image {
            width: 200px;
            height: 200px;
            object-fit: cover;
        }
    }
}

// About Page Styles
.inner-banner {
    background-image: url(https://wp.w3layouts.com/digitaledu/wp-content/themes/digitaledu/assets/images/banner3.jpg);
    background-size: cover;
    background-repeat: no-repeat, no-repeat, no-repeat;
    background-position: center;
    min-height: 400px;
    position: relative;
    z-index: 1;

    .banner-title {
        position: absolute;
        left: 10%;
        top: 30%;
        color: #fff;

        h1 {
            font-weight: 800;
        }

        p {
            font-size: 32px;
            font-weight: 600;
        }

    }
}

.inner-banner:before {
    content: "";
    background: rgb(0 0 0 / 70%);
    position: absolute;
    top: 0;
    min-height: 100%;
    left: 0;
    right: 0;
    z-index: -1;
}

.radius-image-full {
    border-radius: 15px;
}

.why-choose-banner {
    width: 100%;
    height: 100vh;
    background-size: cover;
    object-fit: cover;
    object-position: center;
    // border-radius: 15px;
}

.team {
    .team-img img {
        transition: all .2s ease-in-out;
        overflow: hidden;
        width: 100%;
        border-radius: 50%;
    }

    .team-title {
        margin: 25px 0 0px;
        font-size: 24px;
        color: #000;
        font-weight: 600;
        display: block;
    }

    .team-wrap {
        padding: 8px;
    }
}

// =======================================
// MOBILE SCREEN STYLINGS
// =======================================

@media only screen and (max-width: 767px) {
    .about-desc {
        padding: 0 !important;
    }

    .about-wrapper {
        .about-read-more {
            display: none;
        }

        .AboutPageWhyChooseUs {
            .why-choose-banner {
                height: 30vh;
            }
        }
    }

    .about-us-content {
        padding: 24px !important;
        text-align: justify;
        font-size: 12px;
        border-radius: 30px;
        margin: 32px 0px;
    }



    #myImage {
        height: 100%;
        width: 100%;
    }

    .logo-image {
        position: absolute;
        height: 40%;
        object-fit: cover;
        top: 10%;
        left: 10%;
        transform: translateX(-10%);
    }


    // Founders section styles
    .cards {
        flex-direction: column !important;
    }
}

// =====================================================
// =========== Founders section styles =================
// =====================================================
@mixin mQ($rem) {
    @media screen and (min-width: $rem) {
        @content;
    }
}

$position: -0.375rem;

img {
    max-width: 100%;
    display: block;
}

section {
    span {
        text-transform: uppercase;
        display: block;
        font-size: 1.2rem;
    }


    .cards {
        margin-top: 7rem;
        display: flex;
        flex-direction: row;
        gap: 2rem;


        .card {
            position: relative;
            cursor: pointer;

            .social-links {
                a {
                    margin-right: 15px;
                    font-size: 21px;
                    transition: .6s;
                    color: #fff;
                }
            }

            h3,
            p {
                text-transform: capitalize;
            }

            p {
                letter-spacing: 0.12rem;
                font-weight: 300;
                max-width: 100%;
            }

            &::before {
                position: absolute;
                content: "";
                width: 5rem;
                height: 6.25rem;
                z-index: 0;
                transition: 0.3s ease-in-out;
                background: #f2709c;
                background: -webkit-linear-gradient(to left, #ff9472, #f2709c);
                background: linear-gradient(to left, #ff9472, #f2709c);
                top: $position;
                left: $position;
            }

            &::after {
                position: absolute;
                inset: 0;
                content: "";
                width: 100%;
                height: 100%;
                background: #232526;
                background: -webkit-linear-gradient(to bottom,
                        hsl(210deg 2.99% 26.27% / 20%),
                        hsl(0deg 0% 3.14% / 90%));
                background: linear-gradient(to bottom,
                        hsl(210deg 2.99% 26.27% / 20%),
                        hsl(0deg 0% 3.14% / 90%));
            }

            img {
                filter: grayscale(100%);
                transition: 0.5s ease;
            }

            &-content {
                position: absolute;
                bottom: 0;
                z-index: 99;
                left: 0;
                color: #fff;
                width: 100%;
                padding: 1.875rem 1.25rem;
                text-align: center;

                ul {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 0.625rem;
                    margin-top: 1.25rem;
                }
            }

            &:hover img {
                filter: grayscale(0%);
            }

            &:hover::before {
                transform: scale(1.03);
            }
        }
    }
}




// ===============================================
// =========== Teachers Section Styles ===========
// ===============================================
.slick-list {
    padding: 0px !important;
}

.main {
    background-color: #37424f;
    margin-top: 2em;
    padding: 20px;
    width: auto !important;
    border-radius: 10px;
    background: #232526;
    background: -webkit-linear-gradient(to bottom,
            hsl(210deg 2.99% 26.27% / 20%),
            hsl(0deg 0% 3.14% / 90%));
    background: linear-gradient(to bottom,
            hsl(210deg 2.99% 26.27% / 20%),
            hsl(0deg 0% 3.14% / 90%));

    .profile-card {
        position: relative;
        width: 220px;
        height: 220px;
        background: #f2709c;
        background: -webkit-linear-gradient(to left, #ff9472, #f2709c);
        background: linear-gradient(to left, #ff9472, #f2709c);

        padding: 30px;
        border-radius: 50%;
        box-shadow: 0 0 22px #3336;
        transition: .6s;
        margin: 0 25px;

        .img {
            width: 100%;
            height: 100%;
            transition: .6s;
            z-index: 99;

            img {
                width: 100%;
                border-radius: 50%;
                box-shadow: 0 0 22px #3336;
                transition: .6s;
            }

        }

        .caption {
            text-align: center;
            transform: translateY(-60px);
            opacity: 0;
            transition: .6s;

            p {
                font-size: 15px;
                color: #fff;
                font-family: sans-serif;
                margin: 2px 0 9px 0;
            }

            h3 {
                font-size: 21px;
                font-family: sans-serif;
                color: #fff;
            }
        }

    }

    .profile-card:hover {
        border-radius: 10px;
        height: 260px;

        .img {
            transform: translateY(-60px);

            img {
                border-radius: 10px;

            }
        }

        .caption {
            opacity: 1;
        }
    }
}

.main:hover {
    border-radius: 10px;
}